import firebase from "firebase";

var firebaseConfig = {
  // it is safe to expose this for firebase. not a sec. risk
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_APP_ID

  apiKey: "AIzaSyABdU5_xUy3UA27OXR3JWid2jsXlnyKDy4",
  authDomain: "blast-and-brew-access.firebaseapp.com",
  databaseURL: "https://blast-and-brew-access.firebaseio.com",
  projectId: "blast-and-brew-access",
  storageBucket: "blast-and-brew-access.appspot.com",
  messagingSenderId: "700038284827",
  appId: "00038284827:web:5412ad823c6f98cdb91abe",
};

const fire = firebase.initializeApp(firebaseConfig);
export default fire;
