/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./navigation.css";
import logo from "../src/svgs/logo-01.svg";
// import { Link } from "react-router-dom";

const NavigationStart = () => {
  return (
    <div className="NavItems">
      <img src={logo} alt="My logo" />
      <div className="logo-text">Franchisee Portal</div>

      {/*<nav>
        <ul>
          <li>
            <Link to="/promotions"> Downloads </Link>
          </li>
           <li>
            <a>Marketing</a>
          </li>
          <li>
            <a>Operations</a>
          </li>
          <li>
            <a>Products</a>
          </li> 
        </ul>
      </nav>*/}

      <div className="g1"></div>
      <div className="g2"></div>
      <div className="g3"></div>
    </div>
  );
};

export default NavigationStart;
