import React from "react";
import "./pages.css";
import "./latest.css";
import LatestPosts from "../components/latestLoop";

function latestPull({ handleLogout, deleteItem, user }) {
  return (
    <>
      <section className="mainSection">
        <nav className="hero">
          <h1 className="sr-only">Latest Posts</h1>
          <button onClick={handleLogout}>Log Out</button>
        </nav>

        <main className="grundge1">
          <h1>Latest Posts</h1>
          <section className="full">
            <LatestPosts deleteItem={deleteItem} user={user} />
          </section>
        </main>
      </section>
    </>
  );
}

export default latestPull;
