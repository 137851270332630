import React from "react";
import "./pages.css";
import PhotoTaps from "../components/photographyTapsLoop";

function Taps({ handleLogout, deleteItem, user }) {
  return (
    <>
      <section className="mainSection">
        <nav className="hero">
          <h1 className="sr-only">Photography | Taps</h1>
          <button onClick={handleLogout}>Log Out</button>
        </nav>

        <main>
          <PhotoTaps deleteItem={deleteItem} user={user} />
        </main>
      </section>
    </>
  );
}

export default Taps;
