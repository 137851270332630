import React, { useEffect } from "react";
import "./slideUp.css";

const SlideUp = () => {
  function viewPortFunc() {
    var inScreen = document.querySelectorAll(".inScreen");

    function onScroll() {
      for (var item of inScreen) {
        elementVisible(item);
      }
    }

    function elementVisible(el) {
      let top = el.offsetTop;
      let height = el.offsetHeight;
      let bottom = top + height;

      let IsOverBottom = top > window.pageYOffset + window.innerHeight;
      let IsBeforeTop = bottom < window.pageYOffset;

      if (!IsOverBottom && !IsBeforeTop) {
        // el.classList.add("slideUp");
        // .inScreen SET to 1 vs. 0.5 to trigger above effect.
      }
    }

    window.addEventListener("scroll", onScroll);
  }

  useEffect(() => {
    viewPortFunc();
  }, []);

  return <></>;
};

export default SlideUp;
