import React from "react";
import "./login.css";
import NavigationStart from "../components/navigationStart";
import Slider from "./slideshow1.js";

const Login = (props) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    //handleSignup,
    handleReset,
    hasAccount,
    setHasAccount,
    emailError,
    passwordError,
  } = props;

  const afterSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <header className="navStart">
        <NavigationStart />
      </header>

      <Slider />

      <section className="login">
        <div className="loginContainer">
          <div className="memberLogin">Member Login</div>

          <div className="btnContainer">
            {hasAccount ? (
              <>
                <form onSubmit={afterSubmit}>
                  <label>Account Email</label>
                  <input
                    type="text"
                    autoFocus
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="errMessage">{emailError}</p>

                  <button onClick={handleReset}>Password Reset</button>
                  <p>
                    <span onClick={() => setHasAccount(!hasAccount)}>
                      Sign Back In
                    </span>{" "}
                  </p>
                </form>
              </>
            ) : (
              <>
                <form onSubmit={afterSubmit}>
                  <label>Username</label>
                  <input
                    type="text"
                    autoFocus
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="errMessage">{emailError}</p>

                  <label>Password</label>
                  <input
                    type="text"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <p className="errMessage">{passwordError}</p>

                  <button onClick={handleLogin}>Sign In</button>
                  <p>
                    Forgot Password?
                    <span onClick={() => setHasAccount(!hasAccount)}>
                      Reset
                    </span>
                  </p>
                </form>
              </>
            )}
          </div>
        </div>

        <div className="introContainer">
          American Eatery <span>AND</span> Tap House
        </div>
      </section>
    </>
  );
};

export default Login;
