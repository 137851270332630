import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Dashboard from "../admin/dashboard";
import AllPosts from "./all";

import Home from "./home";
import Latest from "./latest";
import Promotions from "./promotions";
import Social from "./social";
import Digital from "./digital";
import PrintSignage from "./print-signage";
import PrintMenus from "./print-menus";
import PrintMail from "./print-mail";
import PhotosMain from "./photography";
import Taps from "./photography-taps";
import Food from "./photography-food";
import Lifestyle from "./photography-lifestyle";
import Video from "./video";
import fire from "firebase";
import Covid from "./covid-19";
import Operations from "./operations";
import Recipes from "./recipes";
import Merchandise from "./merchandise";
import Nutrition from "./nutritions";

function Start({
  handleLogout,
  hasAccount,
  user,
  checkHomeOne,
  setcheckHomeOne,
}) {
  const ref = fire.firestore().collection("blast");

  function deleteItem(blastItem) {
    ref
      .doc(blastItem.id)
      .delete()
      .then(() => {
        alert("Removed Menu Item");
      })
      .catch((err) => {
        console.log("Not deleted: ", blastItem.id);
        console.error(err);
      });
  }

  return (
    <>
      <Router basename={"/"}>
        <Header
          hasAccount={hasAccount}
          user={user}
          checkHomeOne={checkHomeOne}
          setcheckHomeOne={setcheckHomeOne}
        />

        <Switch>
          {/* <Route exact path={["/", "/portal"]}> */}
          <Route exact path="/">
            <Home
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
            />
          </Route>
          <Route path="/latest">
            <Latest
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>

          {/* Marketing Routes */}
          <Route path="/merchandise">
            <Merchandise
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/promotions">
            <Promotions
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/social">
            <Social
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/digital">
            <Digital
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>

          {/* print main is dead */}
          <Route path="/print-signage">
            <PrintSignage
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/print-menus">
            <PrintMenus
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/print-mail">
            <PrintMail
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>

          <Route path="/photography">
            <PhotosMain
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/photography-taps">
            <Taps
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/photography-food">
            <Food
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/photography-lifestyle">
            <Lifestyle
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/video">
            <Video
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/covid-19">
            <Covid
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          {/* End marketing Route */}

          {/* Operations Routes */}
          <Route path="/operations">
            <Operations
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>

          {/* Recipes Routes */}
          <Route path="/recipes">
            <Recipes
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
          <Route path="/nutrition">
            <Nutrition
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>

          {/* Admin only */}
          <Route exact path="/dashboard">
            <Dashboard
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>

          <Route exact path="/all">
            <AllPosts
              handleLogout={handleLogout}
              user={user}
              checkHomeOne={checkHomeOne}
              setcheckHomeOne={setcheckHomeOne}
              deleteItem={deleteItem}
            />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default Start;
