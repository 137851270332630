import React from "react";
import "./pages.css";
import CovidLoop from "../components/covidLoop";

function Covid({ handleLogout, deleteItem, user }) {
  return (
    <>
      <section className="mainSection">
        <nav className="hero">
          <h1 className="sr-only">Digital Page</h1>
          <button onClick={handleLogout}>Log Out</button>
        </nav>

        <main className="grundge1">
          <CovidLoop deleteItem={deleteItem} user={user} />
        </main>
      </section>
    </>
  );
}

export default Covid;
