import React, { useState, useEffect } from 'react';
import '../pages/pages.css';
import './dashboard.css'
import Add from './add';

function Dashboard({ handleLogout }) {
    const [usr, setUsr] = useState('');

    function loggedIn(){
        const siteuser = localStorage.getItem('SiteUser');
        const parsedUsrEmail = JSON.parse(siteuser).email;
        // const parsedUsrUID = JSON.parse(siteuser).uid;
        
        setUsr(parsedUsrEmail);
    }

    useEffect(() => {
       loggedIn();
    }, [])

    // function collapser(e) {
    //     e.preventDefault();
    //     const content = document.querySelector('section.collapsable');
    //     if (content.style.maxHeight) {
    //         content.style.maxHeight = null;

    //     } else {
    //         content.style.maxHeight = content.scrollHeight + "px";
    //     }
    // }

    return (
        <>
            <section className="mainSection">
                <nav className="hero">
                    <h3 className="sr-only">Admin Page</h3>
                    <button onClick={handleLogout}>Log Out</button>
                </nav>

                <main className="admin">
                    <h3 className="nameIntro"> Hello: {usr}</h3>
                
                    {/* {( usr == "artpineda01@gmail.com") ? <Add/> : null } */}
                    <Add />

                </main>
            </section>
        </>
    );
}

export default Dashboard;        