/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import fire from "firebase";
import { v4 as uuidv4 } from "uuid";
// import MyDropzone from "../components/fileUpload";
import axios from "axios";

import "../pages/pages.css";

const Add = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCat] = useState("Merchandise");
  const [prehead, setPrehead] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDesc] = useState("");
  const [upload, setUpload] = useState("");
  // const [linkFile, setLinkFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState("");
  // const [fileName, setFileNames] = useState("");
  const [video, setVideo] = useState(null);
  const [modifiedDate, setModifiedDate] = useState(null);

  const ref = fire.firestore().collection("blast");

  function clearForm() {
    const inputs = document.querySelectorAll("input");
    const textarea = document.querySelectorAll("textarea");
    inputs.forEach((input) => (input.value = ""));
    textarea.forEach((textarea) => (textarea.value = ""));
  }

  function addItem(newItem) {
    ref
      //.doc() use UUID instead.
      .doc(newItem.id)
      .set(newItem)
      .then(() => {
        alert("New Menu Item Added");
        clearForm();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function verifyUpload() {
    const verifyDiv = document.querySelector(".verifyUploadDiv");
    const verifyDivUpload = document.querySelector(".uploadMainBtn");

    verifyDiv.style.display = "none";

    verifyDivUpload.addEventListener("click", () => {
      verifyDiv.style.display = "flex";
    });
  }

  function handlePreviewUpload(e) {
    setUpload(e.target.files[0]);
  }

  function handleFile(e) {
    let upFile = e.target.files[0];
    setUploadedFile(upFile);
  }

  function handleFileUpload(e) {
    let file = uploadedFile;
    // let fileName = uploadedFile.name;
    // console.log("Uploading: ", fileName);

    let formData = new FormData();
    formData.append("file", file);

    axios({
      url: `https://portal.blastandbrew.com/ajaxUpload.php`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      console.warn("result: ", response);
      alert("Upload successfully.");
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const uploadTask = fire.storage().ref(`/images/${upload.name}`).put(upload);
    //const uploadTask = fire.storage.refFromURL(`gs://blast-and-brew-access.appspot.com/images/${file.name}`).put(file);

    uploadTask.on(
      "state_changed",
      function (snap) {
        //var progress = (snap.bytesTransferred / snap.totalBytes) / 100;
        switch (snap.state) {
          case fire.storage.TaskState.PAUSED:
            console.log("Upload is paused");
            break;
          case fire.storage.TaskState.RUNNING:
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      function (err) {
        switch (err.code) {
          case "storage/unauthorized":
            console.log("not authorized to do this");
            break;
          case "storage/canceled":
            console.log("cancelled");
            break;
          case "storage/unknown":
            console.log("unknown error");
            break;
          default:
            break;
        }
      },
      function complete() {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(function (downloadImageURL) {
            //setUpload(upload);
            setLoading(false);
            // console.log('File available at', downloadImageURL)

            //let newURL = fire.database.ServerValue.TIMESTAMP
            let newURL = upload.lastModifiedDate;
            setModifiedDate(newURL, modifiedDate);

            let uploadedPHPFile = `portal.blastandbrew.com/uploads/${uploadedFile.name}`;
            console.log("State Name: ", uploadedPHPFile);

            //passed some as string, string state name didn't work below.
            addItem({
              id: uuidv4(),
              category,
              prehead,
              title,
              // subtitle,
              description,
              image_preview: downloadImageURL,
              video,
              // link: linkFile,
              link: uploadedPHPFile,
              created: newURL,
            });

            clearForm();
          });
      }
    );
  }

  useEffect(() => {
    verifyUpload();
    //handleFile();
  }, []);

  if (loading) {
    return (
      <div className="loaderWindow">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <section className="addSection collapsable">
        <div className="Add">
          <h1> Add Menu Item </h1>
          <section className="inputbox">
            <form onSubmit={handleSubmit}>
              <h3>Pre Title</h3>
              <input
                text="text"
                placeholder="Daily Promotions"
                onChange={(e) => setPrehead(e.target.value)}
                required
              />

              <h3>Title</h3>
              <input
                text="text"
                onChange={(e) => setTitle(e.target.value)}
                required
              />

              {/* <h3>Details</h3>
              <input
                text="text"
                placeholder="Offer Details"
                onChange={(e) => setSubtitle(e.target.value)}
                required
              /> */}

              <h3>Description</h3>
              <textarea
                rows="8"
                onChange={(e) => setDesc(e.target.value)}
                required
              />

              <h3>YouTube | Vimeo URL (if applicable)</h3>
              <input
                text="text"
                placeholder="NO HTTPS! | EX: www.youtube.com/something"
                onChange={(e) => setVideo(e.target.value + "?rel=0")}
              />

              <h3>Preview Image (jpg / png only)</h3>
              <input type="file" onChange={handlePreviewUpload} required />

              {/* <h3>Download Assets Link</h3>
              <input
                text="text"
                placeholder="NO HTTPS! | EX: www.spaces.hightail.com/space/abcdecfg"
                onChange={(e) => setLinkFile(e.target.value)}
                required
              /> */}

              <h3> Upload File to Download </h3>
              <span className="small">
                <span style={{ color: "red", lineHeight: "2.5" }}>
                  Choose File, Upload File and then Submit to Finalize the
                  Upload.
                </span>
              </span>

              <div className="uploadDiv">
                {/* <MyDropzone stateChanger={setNameState} /> */}
                <input type="file" name="file" onChange={handleFile} required />
                <button
                  type="button"
                  className="uploadMainBtn"
                  onClick={handleFileUpload}
                >
                  Upload
                </button>
              </div>

              <div className="verifyUploadDiv">
                <h3>Select Category</h3>
                <label htmlFor="category" className="sr-only">
                  Select A Category
                </label>

                <select
                  required
                  name="category"
                  id="category"
                  defaultValue="default"
                  value={console.log("value cat: ", category)}
                  onChange={(e) => setCat(e.target.value)}
                >
                  <option defaultValue="default" disabled>
                    Select A Category
                  </option>
                  <option defaultValue="merchandise">Merchandise</option>
                  <option defaultValue="promotions">Promotions</option>
                  <option defaultValue="social_posts">Social Posts</option>
                  <option defaultValue="social_stories">Social Stories</option>
                  <option defaultValue="digital">Digital</option>
                  <option defaultValue="print_signage">Print - Signage</option>
                  <option defaultValue="print_menus">Print - Menus</option>
                  <option defaultValue="print_mail">Print - Mail</option>
                  <option defaultValue="operations">Operations Manual</option>
                  <option defaultValue="recipes">Recipes</option>
                  <option defaultValue="nutritions">Nutritions Guide</option>
                  <option defaultValue="photography_taps">
                    Photography - Taps
                  </option>
                  <option defaultValue="photography_food">
                    Photography - Food
                  </option>
                  <option defaultValue="photography_lifestyle">
                    Photography - Lifestyle
                  </option>
                  <option defaultValue="video">Video</option>
                  <option defaultValue="covid">Covid-19</option>
                </select>

                <button type="submit">Add New Item</button>
              </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
};

export default Add;
