/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import fire from "firebase";
import SlideUp from "./slideUp";

const MerchPosts = ({ deleteItem, user }) => {
  const { email } = user;
  // console.log("is email:", email);

  const [loading, setLoading] = useState(false);
  const [blastItems, setblastItems] = useState([]);

  const Ref = fire
    .firestore()
    .collection("blast")
    .where("category", "==", "Merchandise")
    .orderBy("created", "asc");

  function getItems() {
    setLoading(true);
    Ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setblastItems(items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getItems();
  }, []);

  if (loading) {
    return (
      <div className="loaderWindow">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <SlideUp />

      <h2>Merchandise</h2>
      <ul>
        {blastItems.map((blastItem) => (
          <li key={blastItem.id}>
            <div className="box inScreen">
              {email === "web@jsaweb.com" ? (
                <button
                  className="deleter_3up"
                  onClick={() => deleteItem(blastItem)}
                >
                  X
                </button>
              ) : null}

              <div className="imagePreview">
                <img src={blastItem.image_preview} alt={blastItem.image_name} />
              </div>
              <div className="info">
                <p>{blastItem.description}</p>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`https://${blastItem.link}`, "_blank");
                  }}
                >
                  Download Assets
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MerchPosts;
