import React from 'react';

function Footer(){
    return(
        <footer className="footer">
            Blast & Brew Downloads
        </footer>
    )
}

export default Footer