import React, { useState, useEffect } from "react";
import fire from "../firebase";
import Login from "../components/login";
import Start from "../pages/start";

const Auth = () => {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [hasAccount, setHasAccount] = useState(false);
  const [checkHomeOne, setcheckHomeOne] = useState(false);

  const clearInputs = () => {
    setEmail("");
    setPassword("");
  };

  const clearErrors = () => {
    setEmailError("");
    setPasswordError("");
  };

  const handleLogin = () => {
    fire
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err) => {
        switch (err.code) {
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setEmailError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
          default:
            break;
        }
      });
  };

  const handleSignup = () => {
    clearErrors();
    fire
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch((err) => {
        switch (err.code) {
          case "auth/email-already-in-use":
          case "auth/invalid-email":
            setEmailError(err.message);
            break;
          case "auth/weak-password":
            setPasswordError(err.message);
            break;
          default:
            break;
        }
      });
  };

  const handleReset = () => {
    clearErrors();
    fire
      .auth()
      .sendPasswordResetEmail(email)
      .then((user) => {
        alert("Please Check Your Email to Reset Password");
      })
      .catch((err) => {
        setEmailError(err.message);
      });
  };

  const handleLogout = () => {
    fire.auth().signOut();
    localStorage.removeItem("SiteUser");
    // window.location.reload(false);
    window.location.replace(".");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        clearInputs();
        setUser(user);
        //setcheckHomeOne(checkHomeOne);

        localStorage.setItem("SiteUser", JSON.stringify(user));
      } else {
        setUser();
        localStorage.removeItem("SiteUser");
        localStorage.removeItem(user);
        localStorage.clear();
      }
    });
  };

  useEffect(() => {
    authListener();
  }, [authListener]);

  const userLocal = JSON.parse(localStorage.getItem("SiteUser"));

  return (
    <>
      {!user && !userLocal ? (
        <Login
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
          handleSignup={handleSignup}
          hasAccount={hasAccount}
          handleReset={handleReset}
          setHasAccount={setHasAccount}
          emailError={emailError}
          passwordError={passwordError}
        />
      ) : (
        <>
          <Start
            handleLogout={handleLogout}
            user={user}
            setcheckHomeOne={setcheckHomeOne}
            checkHomeOne={checkHomeOne}
          />
        </>
      )}
    </>
  );
};

export default Auth;
