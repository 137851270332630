/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import fire from "firebase";
import SlideUp from "./slideUp";

const NutritionsLoop = ({ deleteItem, user }) => {
  const { email } = user;

  const [loading, setLoading] = useState(false);
  const [blastItems, setBlastItems] = useState([]);

  //requires index on large queries - see console to automatically do it.
  let promosRef = fire
    .firestore()
    .collection("blast")
    .where("category", "==", "Nutritions Guide")
    .orderBy("created", "asc");

  function getItems() {
    setLoading(true);
    promosRef.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setBlastItems(items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getItems();
  }, []);

  if (loading) {
    return (
      <div className="loaderWindow">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <SlideUp />

      <h1>Nutritions Guide</h1>

      <section className="promosSec">
        <ul>
          {blastItems.map((blastItem) => (
            <li key={blastItem.id}>
              <div className="box inScreen">
                {email === "web@jsaweb.com" ? (
                  <button
                    className="deleter_full"
                    onClick={() => deleteItem(blastItem)}
                  >
                    X
                  </button>
                ) : null}
                <div className="imagePreview">
                  <img src={blastItem.image_preview} alt={blastItem.title} />
                </div>
                <div className="info">
                  <span>{blastItem.prehead}</span>
                  <h2>{blastItem.title}</h2>
                  <h3>{blastItem.subtitle}</h3>
                  <p>{blastItem.description}</p>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`https://${blastItem.link}`, "_blank");
                    }}
                  >
                    Download Assets
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default NutritionsLoop;
