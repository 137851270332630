import React from "react";

export default function Bar() {
  return (
    <div className="InfoHeader">
      NEED SOMETHING IN A DIFFERENT FORMAT OR DESIGN FILES? Send us a request
      and we’ll get back to you within 48 hours!
      <a href="mailto:bbatti@jsaweb.com?cc=nramirez@jsaweb.com&amp;subject=Request%20for%20Blast%20and%20Brew%20Design%20Files">
        <button>EMAIL</button>
      </a>
    </div>
  );
}
