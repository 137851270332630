import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slideshow.css";

import hero1 from '../src/images/hero1.jpg';
import hero2 from '../src/images/hero2.jpg';
import hero3 from '../src/images/hero3.jpg';

export default function App() {

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 5500,
        autoplaySpeed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false
    };

    return (
    <section className="slide_root">
        <div className="slides_contain">
            <Slider { ...settings }>

              <div>
                <img src={hero1} alt="image1" />
              </div>
              <div>
                <img src={hero2} alt="image2"/>
              </div>
              <div>
                <img src={hero3} alt="image2"/>
              </div>
              
            </Slider>
        </div>

    </section>
    );
}