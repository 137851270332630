/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import fire from "firebase";
import SlideUp from "./slideUp";
import ReactPlayer from "react-player";
import backupImage from "../src/images/placeholder_vid.jpg";

const VideoPosts = ({ deleteItem, user }) => {
  const { email } = user;

  const [loading, setLoading] = useState(false);
  const [blastItems, setblastItems] = useState([]);

  const socialRef = fire
    .firestore()
    .collection("blast")
    .where("category", "==", "Video")
    .orderBy("created", "desc");

  function getItems() {
    setLoading(true);
    socialRef.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setblastItems(items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getItems();
  }, []);

  if (loading) {
    return (
      <div className="loaderWindow">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <SlideUp />

      <ul className="videoUL">
        {blastItems.map((blastItem) => (
          <li key={blastItem.id}>
            <div className="box inScreen">
              {email === "web@jsaweb.com" ? (
                <button
                  className="deleter_3up"
                  onClick={() => deleteItem(blastItem)}
                >
                  X
                </button>
              ) : null}

              <div className="imagePreview">
                {blastItem.video != null ? (
                  <ReactPlayer
                    playing={true}
                    playsinline={true}
                    muted
                    url={blastItem.video}
                    controls={false}
                    loop={true}
                    width="100%"
                  />
                ) : (
                  <img
                    src={
                      blastItem.image_preview
                        ? blastItem.image_preview
                        : { backupImage }
                    }
                    alt={blastItem.image_name}
                  />
                )}
              </div>
              <div className="info">
                <p>{blastItem.description}</p>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`https://${blastItem.link}`, "_blank");
                  }}
                >
                  Download Assets
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default VideoPosts;
