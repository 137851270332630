import React from "react";
import "./pages.css";
import MerchPosts from "../components/merchLoop";

function Merchandise({ handleLogout, deleteItem, user }) {
  return (
    <>
      <section className="mainSection">
        <nav className="hero">
          <h1 className="sr-only">Social Page</h1>
          <button onClick={handleLogout}>Log Out</button>
        </nav>

        <main className="grundge1">
          <h1>Merchandise</h1>
          <section className="socialSec">
            <MerchPosts deleteItem={deleteItem} user={user} />
          </section>
        </main>
      </section>
    </>
  );
}

export default Merchandise;
