import "./App.css";
import "./Accessible.css";
import Auth from "./components/auth";
function App() {
  // start with auth
  return (
    <div className="App">
      <div className="AppMain">
        <section className="root">
          <Auth />
        </section>
      </div>
    </div>
  );
}

export default App;
