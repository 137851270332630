import React from "react";
import Navigation from "./navigation";
import Bar from "./bar";

function Header({ hasAccount, user, checkHomeOne, setcheckHomeOne }) {
  return (
    <>
      <header className="navStart">
        <Navigation
          hasAccount={hasAccount}
          user={user}
          checkHomeOne={checkHomeOne}
          setcheckHomeOne={setcheckHomeOne}
        />
      </header>

      <Bar />
    </>
  );
}

export default Header;
