import React from "react";
import "./pages.css";
import PrintMenuLoop from "../components/printMenuLoop";

function PrintMenus({ handleLogout, deleteItem, user }) {
  return (
    <>
      <section className="mainSection">
        <nav className="hero">
          <h1 className="sr-only">Print Menus Page</h1>
          <button onClick={handleLogout}>Log Out</button>
        </nav>

        <main>
          <PrintMenuLoop deleteItem={deleteItem} user={user} />
        </main>
      </section>
    </>
  );
}

export default PrintMenus;
