/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import fire from "firebase";
import SlideUp from "./slideUp";

const PhotoLife = ({ deleteItem, user }) => {
  const { email } = user;

  const [loading, setLoading] = useState(false);
  const [blastItems, setBlastItems] = useState([]);

  const ref = fire
    .firestore()
    .collection("blast")
    .where("category", "==", "Photography - Lifestyle")
    .orderBy("created", "asc");

  function getItems() {
    setLoading(true);

    function Snapper(which) {
      which.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setBlastItems(items);
        setLoading(false);
      });
    }
    if (ref) {
      Snapper(ref);
    }
  }

  useEffect(() => {
    getItems();
  }, []);

  if (loading) {
    return (
      <div className="loaderWindow">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <SlideUp />

      <h1>Lifestyle </h1>
      <section id="sig" className="promosSec print">
        <h2 className="signageTitle">Photography Assets</h2>
        <ul>
          {blastItems.map((blastItem) => (
            <li key={blastItem.title}>
              <div className="box inScreen">
                {email === "web@jsaweb.com" ? (
                  <button
                    className="deleter_full"
                    onClick={() => deleteItem(blastItem)}
                  >
                    X
                  </button>
                ) : null}
                <div className="imagePreview">
                  <img src={blastItem.image_preview} alt={blastItem.title} />
                </div>
                <div className="info">
                  <span>{blastItem.prehead}</span>
                  <h2>{blastItem.title}</h2>
                  <h3>{blastItem.subtitle}</h3>
                  <p>{blastItem.description}</p>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`https://${blastItem.link}`, "_blank");
                    }}
                  >
                    Download Assets
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default PhotoLife;
