import React from "react";
import "./pages.css";
import PrintMailLoop from "../components/printMailLoop";

function PrintMail({ handleLogout, deleteItem, user }) {
  return (
    <>
      <section className="mainSection">
        <nav className="hero">
          <h1 className="sr-only">Print Mail Page</h1>
          <button onClick={handleLogout}>Log Out</button>
        </nav>

        <main>
          <PrintMailLoop deleteItem={deleteItem} user={user} />
        </main>
      </section>
    </>
  );
}

export default PrintMail;
