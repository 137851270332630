import React from 'react';
import './pages.css';
import PromoLoop from '../components/promoLoop';

function Promotions({ handleLogout, deleteItem, user}) {

    return (
        <>
            <section className="mainSection">
                <nav className="hero">
                    <h1 className="sr-only">Promos Page</h1>
                    <button onClick={handleLogout}>Log Out</button>
                </nav>

                <main>

                    <PromoLoop deleteItem={deleteItem} user={user} />

                </main>

            </section>
        </>
    );
}

export default Promotions;