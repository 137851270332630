/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import logo from "../src/svgs/logo-01.svg";
import "./navigation.css";
import { Link } from "react-router-dom";

function Navigation(props) {
  const { checkHomeOne } = props;
  //console.log("email name: ", email);

  return (
    <nav className="NavItems">
      <img src={logo} alt="My logo" />

      {!checkHomeOne ? (
        <>
          <div className="logo-text">Franchisee Portal</div>

          <ul>
            <li className="noDD">
              <Link to="/"> Home </Link>
            </li>
            <li className="noDD">
              <Link to="/latest"> Latest Posts </Link>
            </li>

            <li>
              <Link to="#" className="accordion">
                Marketing
              </Link>

              <ul className="panel">
                <li>
                  <Link to="/merchandise"> Merchandise </Link>
                </li>
                <li>
                  <Link to="/promotions"> Promotions </Link>
                </li>
                <li>
                  <Link to="/social"> Social </Link>
                </li>
                <li>
                  <Link to="/digital"> Digital </Link>
                </li>
                <li>
                  <Link to="/print-signage"> Print (Signage) </Link>
                </li>
                <li>
                  <Link to="/print-menus"> Print (Menus) </Link>
                </li>
                <li>
                  <Link to="/print-mail"> Print (Direct Mail) </Link>
                </li>
                <li>
                  <Link to="/photography-taps"> Photos - Taps </Link>
                </li>
                <li>
                  <Link to="/photography-food"> Photos - Food </Link>
                </li>
                <li>
                  <Link to="/photography-lifestyle"> Photos - lifestyle </Link>
                </li>
                <li>
                  <Link to="/video"> Video </Link>
                </li>
                <li>
                  <Link to="/covid-19"> Covid-19 </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#" className="accordion">
                Operations
              </Link>
              <ul className="panel">
                <li className="subLi">
                  <Link to="/operations"> Operations Manual </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="accordion">
                Product
              </Link>
              <ul className="panel">
                <li className="subLi">
                  <Link to="/recipes"> Recipes </Link>
                </li>
                <li className="subLi">
                  <Link to="/nutrition"> Nutritional Guide </Link>
                </li>
              </ul>
            </li>

            {props.user.email === "web@jsaweb.com" ? (
              <>
                <li className="admin">
                  <Link to="/dashboard"> Dashboard </Link>
                </li>
              </>
            ) : null}

            {props.user.email === "apineda@jsaweb.com" ? (
              <>
                <li className="admin">
                  <Link to="/dashboard"> Dashboard </Link>
                </li>
                <li className="all">
                  <Link to="/all"> All Posts </Link>
                </li>
              </>
            ) : null}
          </ul>
        </>
      ) : null}

      <div className="g1"></div>
      <div className="g2"></div>
      <div className="g3"></div>
    </nav>
  );
}

export default Navigation;
