import React, { useEffect } from "react";
import "./pages.css";
import "./home.css";
import { Link } from "react-router-dom";

function Home({ handleLogout, checkHomeOne, setcheckHomeOne }) {
  function initStart() {
    var external_url =
      "http://blastandbrew.com/portal-pdfs/BAB004530_001_Electronic_FranchiseToolKit_2021.pdf";

    //custom exitPopup by div class name
    const exit_site = document.querySelectorAll(".exit_site");
    exit_site.forEach((eachExitSiteClassLink) => {
      eachExitSiteClassLink.classList.add("exit_target");

      function onExitLink(e) {
        e.preventDefault();
        const externalLink = external_url;

        const exitPopDiv = document.createElement("div");
        exitPopDiv.classList.add("popup_wrapper");

        exitPopDiv.innerHTML = `
            <div class="inner-pop">
              <h1>How To:</h1>
              <p>Navigate: On the table of contents, click the section you wish to explore and it will automatically direct you there.</p>
              <p>Download: Click the arrow pointing down on the top right and it will automatically download to your computer.</p>
              <div class="control">
                <a href="${externalLink}"> Continue </a>
                <a class="goBack"> Cancel </a>
            </div>
        `;
        document.body.appendChild(exitPopDiv);

        let goBack = document.querySelectorAll(".goBack");
        console.log("els: ", goBack);
        goBack.forEach((backer) => {
          backer.addEventListener("click", () => {
            exitPopDiv.remove();
            // window.location.reload(false);
          });
        });
      }
      eachExitSiteClassLink.addEventListener("click", onExitLink);
    });
  }

  const checkMenu = () => {
    setcheckHomeOne(!checkHomeOne);
    // console.log("status: ", checkHomeOne);
  };

  useEffect(() => {
    checkMenu();
    initStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="mainSection">
        <nav className="hero">
          <h1 className="sr-only">Home Page</h1>
          <button onClick={handleLogout}>Log Out</button>
        </nav>

        <main className="mainHome">
          <div>
            <h2>
              FRANCHISE <br /> TOOL KIT
            </h2>
            <h3>
              A Blueprint to Successfully
              <br /> Open Your Blast and Brew
            </h3>
            <a
              href="http://blastandbrew.com/portal-pdfs/BAB004530_001_Electronic_FranchiseToolKit_2021.pdf"
              target="_blank"
              rel="noreferrer"
              className="exit_site"
            >
              <img
                src="https://blastandbrew.com/portal-pdfs/BAB004530_001_Electronic_FranchiseToolKit_2021_Thumbnail.jpg"
                alt="new"
              />
            </a>
          </div>
          <div>
            <h2>
              FRANCHISE <br />
              SUPPORT PORTAL
            </h2>
            <h3>
              The Tools Necessary <br /> for Ongoing Success
            </h3>

            <Link to="/latest" onClick={checkMenu}>
              <img
                src="https://blastandbrew.com/portal-pdfs/supportnew.jpg"
                alt="new"
              />
            </Link>
          </div>
        </main>
      </section>
    </>
  );
}

export default Home;
