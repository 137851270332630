/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import fire from 'firebase';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./photosMainLoop.css";

const Lifestyle = () => {

    const [loading, setLoading] = useState(false)
    const [blastItems, setBlastItems] = useState([]);

    const lifeRef = fire.firestore().collection('blast').where('category', '==', 'Photography - Lifestyle').orderBy('created', 'asc').limit(6);

    function getItems(){
        setLoading(true)

        let unsubscribe = lifeRef.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) =>{
                items.push(doc.data());
                unsubscribe();
            });
            setBlastItems(items);
            setLoading(false);
        });
      
    }

    useEffect(() => {
        getItems();
    }, [])

    if(loading){
        return <div className="loaderWindow"><div className="loader"></div></div>
    }

    const settings = {
        dots: false,
        fade: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 2500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
    };

    return (
        <>
        <h2>Lifestyle</h2>            

        <Slider { ...settings }>
            {blastItems.map((blastItem, index) => (
                <li key={index}>
                    <div className="box">
                        <div className="imagePreview">
                            <img src={blastItem.image_preview} alt={blastItem.title}/>
                        </div>
                        <div className="info">
                            <p>{blastItem.description}</p>
                            <button
                                type = "button"
                                onClick={ (e) => {
                                    e.preventDefault();
                                    window.open(`https://${blastItem.link}`, '_blank');
                                }}
                            >
                                Download Assets
                            </button>
                        </div>
                    </div>
                </li>
        ))}
        </Slider>
            
        </>
    )
}

export default Lifestyle