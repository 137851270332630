import React from 'react';
import "./photosMainLoop.css";
import Lifestyle from './photosLifestyle';
import Taps from './photosTaps'
import Food from './photosFood';

const PhotosMainLoop = () => {

    return (
        <>
        <h1>Photography</h1>
        <section className="threeCol">
            <ul>
                <div className="wrapSlider">
                    <Taps />
                </div>
            </ul>
        </section>

        <section className="threeCol">
            <ul>
                <div className="wrapSlider">
                    <Food />
                </div>
            </ul>
        </section>

        <section className="threeCol">
            <ul>
                <div className="wrapSlider">
                    <Lifestyle />
                </div>
            </ul>
        </section>
        </>
    )
}

export default PhotosMainLoop